import React from "react";
import { combineReducers, createStore } from "redux";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes, useLocation, useParams } from "react-router-dom";
import {
  Login,
  Logout,
  LoginReducer,
  MenuReducer,
  MessageReducer,
  NoticeReducer,
  ConfirmReducer,
  ContractSelectReducer,
  ConstructionSiteSelectReducer,
  TruckSelectReducer,
  TimeRangeReducer,
} from "components";
import EdgeFillingReducer from "../edgeFilling/Reducer";

import App from "./Application.js";
import EdgeFillingNew from "../edgeFilling/EdgeFillingNew.js";
import EdgeFillings from "../edgeFilling/EdgeFillings";
import Trucks from "../truck/Trucks.js";
import TruckNew from "../truck/TruckNew.js";
import TruckEdit from "../truck/TruckEdit.js";
import config from "../../settings/config";

export const store = createStore(
  combineReducers({
    login: LoginReducer,
    menu: MenuReducer,
    message: MessageReducer,
    notice: NoticeReducer,
    confirm: ConfirmReducer,
    edgeFilling: EdgeFillingReducer,
    constructionSiteSelect: ConstructionSiteSelectReducer,
    contractSelect: ContractSelectReducer,
    truckSelect: TruckSelectReducer,
    timeRange: TimeRangeReducer,
  }),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const AppWithLocation = ({ store }) => {
  const location = useLocation();

  return <App store={store} location={location} />;
};

const TruckEditWithParams = ({ store }) => {
  const params = useParams();

  return <TruckEdit store={store} params={params} />;
};

const Root = () => (
  <Provider store={store}>
    <span id="timer" />
    <BrowserRouter>
      <AppWithLocation store={store} />
      <Routes id={config.reptail ? "reptail" : ""}>
        <Route path="/login" element={<Login store={store} />} />
        <Route path="/logout" element={<Logout store={store} />} />
        <Route path="/edgefilling" element={<EdgeFillingNew store={store} />} />
        <Route path="/edgefillings" element={<EdgeFillings store={store} />} />
        <Route path="/trucks" element={<Trucks store={store} />} />
        <Route path="/trucks/new" element={<TruckNew store={store} />} />
        <Route path="/truck/:id" element={<TruckEditWithParams store={store} />} />
      </Routes>
    </BrowserRouter>
  </Provider>
);

export default Root;
