import React from 'react';
import { connect } from 'react-redux';
import {
  addEdgeFilling, addEdgeFillings, removeEdgeFilling, clearEdgeFillings, changeEdgeFilling,
  updateLastPath, addPathToLastEdgeFilling, removeLastPath
} from './Actions';
import {
  DataField, showNotice, showConfirm, showMessage, ContractSelect,
  ConstructionSiteSelect, selectTruck
} from 'components';
import {
  fetch, integerValue, floatValue, Socket, timer, toETRSTM35FIN, testIsValidRoadInfo, paddedNumber,
  toRadians, toDegrees, toWGS84, stateValueParser, ROAD_URL, calculateDistance,
  calculateRoadDistance, getRoadData
} from '../utils.js';
import { MapView, RoadwaySelect, DirectionSelect, AccuracyFixer } from './Components';
import ChangeEdgeFillingView from './Components';
import './EdgeFilling.css';


const View = props => {
  if (props.view === 0) {
    return (
      <div>
        <BasicInfo changeState={props.changeState} height={props.height}
          width={props.width} mass={props.mass}
          varietyWeight={props.varietyWeight}
          variety={props.variety} pit={props.pit}
          pits={props.pits} goAddingPit={props.goAddingPit}
          lastHeight={props.lastHeight} lastWidth={props.lastWidth}
          lastVarietyWeight={props.lastVarietyWeight}
          lastVariety={props.lastVariety} lastPit={props.lastPit} store={props.store} />
      </div>
    );
  }
  else if (props.view === 1) {
    return (
      <div>
        <MassInput changeState={props.changeState}
          roadway={props.roadway}
          direction={props.direction}
          startRoadPart={props.startRoadPart}
          startRoadPole={props.startRoadPole}
          location_road_number={props.location_road_number}
          road={props.selectedConstructionSite ? props.selectedConstructionSite.get('road_number') : '-'}
          newEdgeFilling={props.newEdgeFilling}
          useLocation={props.useLocation}
          notice={props.notice}
          dailyCount={props.dailyCount} lastPart={props.lastPart}
          lastPole={props.lastPole}
          edgeFillings={props.edgeFillings}
          autoGPS={props.autoGPS}
          endEdgeFilling={props.endEdgeFilling}
          creatingEdgeFilling={props.creatingEdgeFilling}
          disableSubmit={props.disableSubmit}
          lastDirection={props.lastDirection}
          lastRoadway={props.lastRoadway}
          newPath={props.newPath}
          disableNewPath={props.disableNewPath} />
      </div>
    );
  }
  else if (props.view === 2) {
    return (
      <div>
        <TrackingInfo changeState={props.changeState} edgeFillings={props.edgeFillings}
          target={props.selectedConstructionSite}
          dailyMass={props.dailyMass}
          dailyDistance={props.dailyDistance}
          wholeDistance={props.wholeDistance}
          wholeMass={props.wholeMass}
          loading={props.loadingEdgeFillings}
          removeLastPath={props.removeLastPath}
          removeEdgeFilling={props.confirmRemoveEdgeFilling}
          removeLocalEdgeFilling={props.confirmRemoveLocalEdgeFilling}
          roadNumber={props.location_road_number} roadPart={props.location_road_part}
          roadPole={props.location_road_pole} accuracy={props.accuracy}
          time={props.locationTime} changeEdgeFilling={props.changeEdgeFilling} />
      </div>
    );
  }
  else {
    return (
      <div>
        <MapView edgeFillings={props.edgeFillings} yourLaditude={props.latitude} yourLongitude={props.longitude}
          roadNumber={props.roadNumber} roadPart={props.roadPart}
          roadPole={props.roadPole} accuracy={props.accuracy}
          showMessage={props.showMessage} site={props.selectedConstructionSite}
          mapPaths={props.mapPaths} mapZoom={props.mapZoom}
          mapPosition={props.mapPosition} organizationId={props.organizationId} />
      </div>
    );
  }
};

const BasicInfo = props => {
  return (
    <fieldset>
      <legend>
        <h4>Perustiedot</h4>
      </legend>
      <div className='row'>
        <div className='column'>
          <ContractSelect required store={props.store} />
        </div>
        <div className='column'>
          <ConstructionSiteSelect required store={props.store} />
        </div>
      </div>
      <div className='row'>
        <div className="column">
          <label htmlFor='width'>Leveys (mm)</label>
          {props.lastWidth != null ? <div>{'Edellinen: ' + props.lastWidth + ' mm'}<br /></div> : null}
          <input id='width' type='tel'
            onChange={props.changeState.bind(this, 'width', 'float', 0.0)}
            value={props.width} required />
        </div>
        <div className="column">
          <label htmlFor='height'>Korkeus (mm)</label>
          {props.lastHeight != null ? <div>{'Edellinen: ' + props.lastHeight}<br /></div> : null}
          <input id='height' type='tel'
            onChange={props.changeState.bind(this, 'height', 'float', 0.0)}
            value={props.height} required />
        </div>
        <div className='column'>
          <label htmlFor='mass'>Kuorma (Tonnit) </label>
          <input id='mass' type='tel'
            value={props.mass}
            onChange={props.changeState.bind(this, 'truckMass', 'float', 0.0)}
            required />
        </div>
      </div>
      <div className='row'>
        <div className="column">
          <label htmlFor='variety'>Lajike</label>
          {props.lastVariety ? <div>{'Edellinen: ' + props.lastVariety}<br /></div> : null}
          <select id='variety'
            onChange={props.changeState.bind(this, 'variety', 'string', '')}
            value={props.variety}>
            <option value=''>Valitse lajike</option>
            <option value='0-16'>0-16</option>
            <option value='0-32'>0-32</option>
            <option value='0-45'>0-45</option>
            <option value='0-55'>0-55</option>
            <option value='0-63'>0-63</option>
          </select>
        </div>
        <div className="column">
          <label htmlFor='varietyWeight'>Lajike ominaispaino</label>
          {props.lastVarietyWeight != null ? <div>{'Edellinen: ' + props.lastVarietyWeight}<br /></div> : null}
          <input id='varietyWeight' type='tel'
            onChange={props.changeState.bind(this, 'varietyWeight', 'float', 0.0)}
            value={props.varietyWeight} required />
        </div>
        <div className="column">
          <label htmlFor='pit'>Monttu</label>
          {props.lastPit ? <div>{'Edellinen: ' + props.lastPit}<br /></div> : null}
          <select id='pit' className='float-left'
            onChange={props.changeState.bind(this, 'pit', 'string', '')}
            value={props.pit}>
            <option value=''>Valitse monttu</option>
            {
              props.pits.map((pit, index) => (
                <option key={index} value={pit}>
                  {pit}
                </option>
              ))
            }
          </select>
          <button className='button-outline' onClick={props.goAddingPit}>
            Uusi monttu
          </button>
        </div>
      </div>
    </fieldset>
  );
};

const CurrentData = props => {
  return (
    <div>
      Päivän matka: <strong>{props.dailyDistance || '-'}</strong> m
      <br />
      Päivän käytetty massa: <strong>{props.dailyMass || '-'}</strong> tonnia
      <br />
      Kohteen matka: <strong>{props.wholeDistance || '-'}</strong> m
      <br />
      Kohteen käytetty massa: <strong>{props.wholeMass || '-'}</strong> tonnia
    </div>
  );
}

const MassInput = props => {
  let road = props.road;

  if (road == null) {
    road = props.location_road_number;
  }

  return (
    <fieldset>
      <legend>
        <h4>Levitystiedot</h4>
      </legend>
      <div>
        <div className='row borders'>
          <div className='column'>
            <label htmlFor='road'>Tie</label>
            <input id='road' type='text' value={road || ''} readOnly />
          </div>
          <div className='column'>
            <label htmlFor='roadPart'>Tieosa</label>
            Edellinen: {props.lastPart || '-'}
            <input id='roadPart' type='tel'
              value={props.startRoadPart}
              onChange={props.changeState.bind(this, 'road_part', 'integer', 0)}
              readOnly={props.autoGPS}
              required />
          </div>
          <div className='column'>
            <label htmlFor='pole'>Paalu</label>
            Edellinen: {props.lastPole || '-'}
            <input id='pole' type='tel'
              value={props.startRoadPole}
              onChange={props.changeState.bind(this, 'road_pole', 'integer', 0)}
              readOnly={props.autoGPS}
              required />
            <label className='checkbox'>
              Automattinen GPS
              <input type='checkbox'
                onChange={props.changeState.bind(this, 'autoGPS', 'boolean', true)}
                checked={props.autoGPS} />
            </label>
          </div>
        </div>
        <div className='row borders'>
          <div className='column'>
            <DirectionSelect onChange={props.changeState} direction={props.direction}
              last={props.lastDirection} />
          </div>
          <div className='column'>
            <RoadwaySelect onChange={props.changeState} roadway={props.roadway}
              last={props.lastRoadway} />
          </div>
        </div>
        <label htmlFor='notice'>Huomiot</label>
        <input id='notice' type='text' value={props.notice || ''}
          onChange={props.changeState.bind(this, 'notice', 'string', '')} />
        <div className='center row'>
          <div className='column'>
            {props.disableSubmit ? <div className='loader float-left' /> :
              (props.creatingEdgeFilling ?
                <button onClick={props.endEdgeFilling}>Lopeta levitys</button>
                :
                <button onClick={props.newEdgeFilling}>Aloita levitys</button>)
            }
          </div>
          <div className='column'>
            {props.disableNewPath ?
              <div className='loader' />
              :
              <button onClick={props.newPath} className='button-outline'>
                Jatka edellistä kuormaa
              </button>
            }
          </div>
        </div>
      </div>
    </fieldset>
  );
}

const TrackingInfo = props => {
  return (
    <fieldset>
      <legend>
        <h4>Seurantatiedot</h4>
      </legend>
      <FiveLastEdgeFillings edgeFillings={props.edgeFillings} target={props.target}
        loading={props.loading} removeEdgeFilling={props.removeEdgeFilling}
        removeLocalEdgeFilling={props.removeLocalEdgeFilling}
        changeEdgeFilling={props.changeEdgeFilling}
        removeLastPath={props.removeLastPath} />
      <div className='row'>
        <div className='column'>
          <CurrentLocation roadNumber={props.roadNumber} roadPart={props.roadPart}
            roadPole={props.roadPole} loading={props.loading}
            accuracy={props.accuracy} time={props.time} />
        </div>
        <div className='column'>
          <CurrentData dailyMass={props.dailyMass}
            dailyDistance={props.dailyDistance}
            wholeDistance={props.wholeDistance}
            wholeMass={props.wholeMass} />
        </div>
      </div>
    </fieldset>
  );
};

const FiveLastEdgeFillings = props => {
  if (props.target == null) return null;
  if (props.edgeFillings.size === 0) return <p>Ei yhtään lisäystä</p>;
  if (props.loading) return <div className="loader"></div>;

  return (
    <div>
      <h4>5 viimeisintä reunantäyttöä</h4>
      {props.edgeFillings.splice(0, props.edgeFillings.size - 5).map(edgeFilling => {
        const date = new Date(edgeFilling.get('date'));
        const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes());
        let header = [];
        let roadInfo = '(Ei paikka tietoja)';

        if (edgeFilling.get('paths').size === 1) {
          roadInfo = edgeFilling.get('paths').first().get('start_part') + ' / ' + edgeFilling.get('paths').first().get('start_distance') +
            (edgeFilling.get('paths').first().get('end_part') ? (' - ' + edgeFilling.get('paths').first().get('end_part') + ' / ' +
              edgeFilling.get('paths').first().get('end_distance')) : '');
        }
        else if (edgeFilling.get('paths').size > 1) {
          roadInfo = edgeFilling.get('paths').size + ' levitystä';
        }

        header.push(<span key={edgeFilling.get('id')}>{
          time + ' | ' + (edgeFilling.get('distance') ? (edgeFilling.get('distance') + ' m | ') : '') +
          roadInfo + ' | Massa: ' + (edgeFilling.get('mass') + ' tonnia' || '-')}
        </span>);
        let data = {
          'Huomiot': edgeFilling.get('notice') || '-',
          'Leveys (mm)': edgeFilling.get('width') || '-',
          'Korkeus (mm)': edgeFilling.get('height') || '-',
          'Lajike': edgeFilling.get('variety') || '-',
          'Lajike ominaispaino': edgeFilling.get('variety_weight') || '-',
          'Monttu': edgeFilling.get('pit') || '-'
        }

        if (edgeFilling.get('truck_mass')) {
          data['Rekka'] = edgeFilling.get('truck').get('register_number');
          data['Kuorman koko'] = edgeFilling.get('truck_mass');
        }

        if (edgeFilling.get('not_saved')) {
          header.push(<span key={edgeFilling.get('id') + '-not_saved'}>
            {edgeFilling.get('not_saved') ? ' (Ei palvelimella)' : ''}
          </span>);
        }

        return (
          <div className='datafield' key={edgeFilling.get('id')}>
            <DataField header={header} data={data} class={edgeFilling.get('not_saved') ? 'yellow' : null}>
              <br />
              {edgeFilling.get('paths').map((path, index) => (
                <p key={index}>
                  {edgeFilling.get('paths').size > 1 ?
                    <span>
                      <strong>{index + 1 + '. '}</strong>
                      {path.get('start_part') + ' / ' + path.get('start_distance') +
                        ' - ' + (path.get('end_part') ? (path.get('end_part') + ' / ' + path.get('end_distance')) : '')}
                    </span>
                    : null}
                  <strong>Ajorata:</strong> {' ' + path.get('roadway') + ' '}
                  <strong>Suunta:</strong> {' ' + path.get('direction') + ' '}
                  {path.get('length') != null ? <span><strong>Pituus:</strong>{' ' + path.get('length') + ' m '}</span>
                    : null}
                </p>
              ))
              }
              <div className='row center'>
                <div className='column'>
                  <button onClick={props.changeEdgeFilling.bind(this, edgeFilling)}>
                    Muokkaa
                  </button>
                </div>
                {edgeFilling.get('paths').size > 1 ?
                  <div className='column'>
                    <button className='button-outline' onClick={props.removeLastPath.bind(this, edgeFilling)}>
                      Poista viimeisin levitys
                    </button>
                  </div>
                  : null
                }
                <div className='column'>
                  <button className='button-outline' onClick={edgeFilling.get('not_saved') ?
                    props.removeLocalEdgeFilling.bind(this, edgeFilling.get('id')) :
                    props.removeEdgeFilling.bind(this, edgeFilling.get('id'))}>
                    Poista
                  </button>
                </div>
              </div>
            </DataField>
          </div>
        );
      })
      }
    </div>
  );
};

const CurrentLocation = props => {
  let accuracyColor;
  let accuracy = props.accuracy;

  if (accuracy > 20) accuracyColor = 'red';
  else if (accuracy > 10) accuracyColor = 'yellow';
  else if (accuracyColor != null) accuracyColor = 'green';

  if (accuracy >= 10000) accuracy = '-';

  return (
    <table>
      <thead>
        <tr>
          <th colSpan={3}>
            Nykyinen sijainti
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {'Tie: '}
            {props.roadNumber || '-'}
          </td>
          <td>
            {'Tieosa: '}
            {props.roadPart || '-'}
          </td>
          <td>
            {'Paalu: '}
            {props.roadPole || '-'}
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            {'Päivitetty: '}
            {props.time || '-'}
          </td>
          <td>
            {'Tarkkuus: '}
            <span id={'location-accuracy-' + accuracyColor}>{accuracy || '-'} </span>m
          </td>
        </tr>
      </tbody>
    </table>
  );
}


class EdgeFillingNew extends React.Component {

  constructor(props) {
    super(props);

    this.watchID = null;

    this.state = {
      direction: integerValue(localStorage.direction, 1),
      roadway: integerValue(localStorage.roadway, 0),
      height: floatValue(localStorage.height, 0),
      width: floatValue(localStorage.width, 250),
      variety: localStorage.variety || '0-16',
      varietyWeight: floatValue(localStorage.varietyWeight, 0),
      pit: localStorage.pit || '',
      road_part: integerValue(localStorage.road_part, 0),
      road_pole: integerValue(localStorage.road_pole, 0),
      truckMass: floatValue(localStorage.truckMass, 0),
      notice: localStorage.notice || '',
      loadingLocation: false,
      dailyCount: 0,
      dailyMass: 0,
      view: 0,
      autoGPS: localStorage.autoGPS === 'false' ? false : true,
      creatingEdgeFilling: localStorage.creatingEdgeFilling ? JSON.parse(localStorage.creatingEdgeFilling) : null,
      confirmed: false,
      traveledDistance: 0,
      currentMassUsage: 0,
      pits: localStorage.pits ? JSON.parse(localStorage.pits) : [],
      paths: {},
      mapPaths: []
    };

    this.saveEdgeFilling = this.saveEdgeFilling.bind(this);
    this.sendSavedEdgeFillings = this.sendSavedEdgeFillings.bind(this);
    this.changeState = this.changeState.bind(this);
    this.setLocation = this.setLocation.bind(this);
    this.useLocation = this.useLocation.bind(this);
    this.locationError = this.locationError.bind(this);
    this.confirmRoadInfo = this.confirmRoadInfo.bind(this);
    this.endEdgeFilling = this.endEdgeFilling.bind(this);
    this.makeEndForPath = this.makeEndForPath.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.goChangeEdgeFilling = this.goChangeEdgeFilling.bind(this);
    this.clearChangeEdgeFilling = this.clearChangeEdgeFilling.bind(this);
    this.changeLocalEdgeFilling = this.changeLocalEdgeFilling.bind(this);
    this.changeEdgeFilling = this.changeEdgeFilling.bind(this);
    this.confirmRemoveEdgeFilling = this.confirmRemoveEdgeFilling.bind(this);
    this.removeEdgeFilling = this.removeEdgeFilling.bind(this);
    this.confirmRemoveLocalEdgeFilling = this.confirmRemoveLocalEdgeFilling.bind(this);
    this.removeLocalEdgeFilling = this.removeLocalEdgeFilling.bind(this);
    this.confirmRemoveLastPath = this.confirmRemoveLastPath.bind(this);
    this.removeLastPath = this.removeLastPath.bind(this);
    this.getDailyData = this.getDailyData.bind(this);
    this.setView = this.setView.bind(this);
    this.goAddingPit = this.goAddingPit.bind(this);
    this.addPit = this.addPit.bind(this);
    this.toggleAccuracyFixer = this.toggleAccuracyFixer.bind(this);
    this.getLastEdgeFillingData = this.getLastEdgeFillingData.bind(this);
    this.newPath = this.newPath.bind(this);
    this.getAutomaticDirection = this.getAutomaticDirection.bind(this);
  }

  componentDidMount () {
    if (localStorage['login'] == null) {
      return;
    }

    if (this.socket == null && typeof (WebSocket) !== 'undefined') {
      this.socket = Socket('/data/edgefilling');
      this.socket.onmessage = async function (e) {
        const data = JSON.parse(e.data);
        if (data['operation'] === 'create') {
          if (data.model.construction_site) {
            if (this.props.selectedConstructionSite.get('id') !== data.model.constructionSiteId) {
              return;
            }
          }
          else {
            const exist = this.props.edgeFillings.find(mass => mass.paths_id === data.model.paths_id);
            if (!exist) {
              return;
            }
          }

          this.getMacadams(this.props.selectedConstructionSite.get('id'), true);
        }
        else if (data['operation'] === 'update') {
          if (data.model.construction_site) {
            if (this.props.selectedConstructionSite.get('id') !== data.model.construction_site.id) {
              return;
            }

            if (data.model.paths_id) {
              data.model.paths = await this.getPaths(data.model.paths_id);
            }

            this.props.changeEdgeFilling(data.model);
          }
          else {
            const exist = this.props.macadams.find(mass => mass.paths_id === data.model.paths_id);
            if (exist) {
              this.getEdgeFillings(this.props.selectedConstructionSite.get('id'), true);
            }
          }
        }
        else if (data['operation'] === 'delete') {
          this.props.removeEdgeFilling(data.model);
        }
      }.bind(this)
    }

    if (this.props.selectedConstructionSite != null) {
      this.getEdgeFillings(this.props.selectedConstructionSite.get('id'));
    }

    this.watchID = navigator.geolocation.watchPosition(this.setLocation, this.locationError,
      { enableHighAccuracy: true });
  }

  componentDidUpdate (lastProps, lastState) {
    if (lastProps.selectedConstructionSite != null &&
      this.props.selectedConstructionSite == null &&
      this.state.view !== 0) {
      this.setState({ view: 0 });
    }

    if (lastState.autoGPS !== this.state.autoGPS && this.state.autoGPS) {
      this.useLocation(true);
    }

    if (lastState.creatingEdgeFilling !== this.state.creatingEdgeFilling) {
      this.getLastEdgeFillingData(this.props.edgeFillings, this.state.creatingEdgeFilling);
    }
    else if (lastProps.edgeFillings !== this.props.edgeFillings) {
      this.getLastEdgeFillingData(this.props.edgeFillings, this.state.creatingEdgeFilling);
      this.getDailyData(this.props.edgeFillings);
      this.getMapPaths(this.props.edgeFillings, this.props.selectedConstructionSite ?
        this.props.selectedConstructionSite.get('id') : null);
    }

    if (this.props.selectedContract == null || this.props.selectedConstructionSite == null) {
      lastProps.clearEdgeFillings();
      return;
    }

    if (lastProps.selectedConstructionSite === this.props.selectedConstructionSite) {
      return;
    }

    this.getEdgeFillings(this.props.selectedConstructionSite.get('id'));
  }

  componentWillUnmount () {
    navigator.geolocation.clearWatch(this.watchID);
    if (this.socket != null) this.socket.close();
  }

  changeState (propertyName, type, defaultValue, event) {
    const value = stateValueParser(event, type, defaultValue);

    if (value == null) {
      return;
    }

    this.setState({ [propertyName]: value });

    if (typeof (Storage) !== 'undefined') {
      localStorage[propertyName] = value;
    }
  }

  async setLocation (position) {
    let latitude = null;
    let longitude = null;
    let accuracy = null;

    if (position.coords) {
      if (this.state.externalGPS) {
        return;
      }

      latitude = position.coords.latitude;
      longitude = position.coords.longitude;
      accuracy = Math.ceil(position.coords.accuracy);
    }
    else {
      latitude = position.lat;
      longitude = position.lon;
    }

    const time = new Date();

    this.setState({
      latitude: latitude,
      longitude: longitude,
      accuracy: accuracy,
      locationTime: paddedNumber(time.getHours()) + ':' + paddedNumber(time.getMinutes()) + ':' + paddedNumber(time.getSeconds())
    });

    let road;

    if (this.props.selectedConstructionSite != null) {
      road = this.props.selectedConstructionSite.get('road_number');
    }

    const converted = toETRSTM35FIN(latitude, longitude);
    const data = await getRoadData(converted.y, converted.x, accuracy, road);

    if (data != null) {
      this.setState({
        locationError: false,
        location_road_number: data.road,
        location_road_part: data.part,
        location_road_pole: data.distance
      }, () => {
        this.useLocation(this.state.autoGPS);
      });
    }
    else {
      const data = await getRoadData(converted.y, converted.x, accuracy);

      if (data != null) {
        this.setState({
          locationError: false,
          location_road_number: data.road,
          location_road_part: data.part,
          location_road_pole: data.distance
        });
      }
      else {
        this.setState({
          locationError: true,
          location_road_number: null,
          location_road_part: null,
          location_road_pole: null
        });
      }
    }
  }

  async useLocation (autoGPS) {
    const roadNumber = this.state.location_road_number;

    if (this.props.selectedConstructionSite &&
      this.props.selectedConstructionSite.get('road_number') !== roadNumber) {
      return;
    }

    if (this.state.road_part < this.state.location_road_part ||
      (this.state.road_part === this.state.location_road_part &&
        this.state.road_distance < this.state.location_road_distance)) {
      this.setState({ direction: 1 });
    }
    else {
      this.setState({ direction: 2 });
    }

    const creatingEdgeFilling = this.state.creatingEdgeFilling;

    if (creatingEdgeFilling) {
      let traveledDistance = await calculateRoadDistance(roadNumber, creatingEdgeFilling.roadPart, creatingEdgeFilling.roadDistance,
        roadNumber, this.state.location_road_part, this.state.location_road_pole);

      if (traveledDistance == null) {
        traveledDistance = await calculateDistance(this.state.latitude, this.state.longitude,
          creatingEdgeFilling.latitude, creatingEdgeFilling.longitude);
      }

      const currentMassUsage = (creatingEdgeFilling.width / 1000) * (creatingEdgeFilling.height / 1000) *
        creatingEdgeFilling.variety_weight * traveledDistance / 1000;
      this.setState({
        traveledDistance: traveledDistance,
        currentMassUsage: Math.round(currentMassUsage * 100) / 100
      });
    }

    if (!autoGPS) return;

    this.setState({
      road_part: this.state.location_road_part || '',
      road_pole: this.state.location_road_pole || ''
    }, () => {
      if (this.state.showAccuracyFixer && this.state.accuracy < 20) {
        this.state.accuracySubmit();
      }
      localStorage.road_part = this.state.road_part;
      localStorage.road_pole = this.state.road_pole;
    });
  }

  locationError (err) {
    this.props.showMessage('Virhe', 'ERROR(' + err.code + '): ' + err.message, 'Error');
  }

  saveEdgeFilling (edgeFilling, path) {
    if (typeof (Storage) !== 'undefined') {
      if (localStorage['savedEdgeFillings'] == null) {
        localStorage['savedEdgeFillings'] = JSON.stringify([]);
      }

      edgeFilling.id = Date.now();
      edgeFilling.not_saved = true;
      edgeFilling.paths = [path];

      let edgeFillings = JSON.parse(localStorage['savedEdgeFillings']);
      edgeFillings.push(edgeFilling);
      localStorage['savedEdgeFillings'] = JSON.stringify(edgeFillings);
    }
    else {
      this.props.showMessage('Virhe', 'Mursketta ei voitu tallentaa paikallisesti eikä palvelimelle', 'Error');
    }
  }

  async sendSavedEdgeFillings () {
    let edgeFillings = JSON.parse(localStorage['savedEdgeFillings']);
    let newEdgeFillings = edgeFillings.slice();
    let error = false;

    for (let index in edgeFillings) {
      let edgeFilling = edgeFillings[index];

      try {
        let newEdgeFilling
        if (!edgeFilling.sent) {
          newEdgeFilling = await fetch('/edgefilling', 'POST', edgeFilling);
        }

        for (let i in edgeFilling.paths) {
          let path = edgeFilling.paths[i];
          path.paths_id = newEdgeFilling.paths_id;

          try {
            await fetch('/path', 'POST', path);
          } catch (err) {
            edgeFilling.sent = true;
            newEdgeFillings[index] = edgeFilling;
          }
        }

        newEdgeFillings.splice(newEdgeFillings.findIndex(e => e['id'] === edgeFilling['id']), 1);
      } catch (err) {
        console.log(err);
        error = true;
      }
    }

    localStorage['savedEdgeFillings'] = JSON.stringify(newEdgeFillings);
    this.getEdgeFillings(this.props.selectedConstructionSite.get('id'), error);
  }

  async sendUpdatedEdgeFillings () {
    let edgeFillings = JSON.parse(localStorage['updatedEdgeFillings']);
    let newEdgeFillings = edgeFillings.slice();
    let error = false;

    for (let index in edgeFillings) {
      const edgeFilling = edgeFillings[index];

      try {
        const data = await fetch('/edgefilling/' + edgeFilling.id, 'GET');

        for (let i in edgeFilling.paths) {
          let path = edgeFilling.paths[i];
          path.paths_id = data.paths_id;

          if (path.id) {
            await fetch('/path/' + path.id, 'PATCH', path);
          }
          else {
            await fetch('/path', 'POST', path);
          }
        }

        newEdgeFillings.splice(newEdgeFillings.findIndex(newEdgeFilling => newEdgeFilling['id'] === edgeFilling['id']), 1);
      } catch (err) {
        console.log(err);
        error = true;
      }
    }

    localStorage['updatedEdgeFillings'] = JSON.stringify(newEdgeFillings);
    this.getEdgeFillings(this.props.selectedConstructionSite.get('id'), error);
  }

  confirmRoadInfo (callback) {
    this.setState({
      confirmed: true
    }, () => callback());
  }

  saveNewPathToLastEdgeFilling (path) {
    const lastEdgeFilling = this.props.edgeFillings.last();

    if (!lastEdgeFilling.get('not_saved')) {
      if (localStorage['updatedEdgeFillings'] == null) {
        localStorage['updatedEdgeFillings'] = JSON.stringify([]);
      }

      let edgeFillings = JSON.parse(localStorage['updatedEdgeFillings']);
      const existingIndex = edgeFillings.findIndex(edgeFilling => edgeFilling.id === lastEdgeFilling.get('id'));

      if (existingIndex !== -1) {
        let paths = edgeFillings[existingIndex].paths;
        paths.push(path);
      }
      else {
        let paths = [];

        lastEdgeFilling.get('paths').forEach(path => {
          paths.push({
            id: path.get('id'),
            road: path.get('road'),
            start_part: path.get('start_part'),
            start_distance: path.get('start_distance'),
            end_part: path.get('end_part'),
            end_distance: path.get('end_distance'),
            length: path.get('length'),
            roadway: path.get('roadway'),
            direction: path.get('direction')
          });
        });

        paths.push(path);

        const edgeFilling = {
          id: lastEdgeFilling.get('id'),
          paths: paths
        }

        edgeFillings.push(edgeFilling);
      }

      localStorage['updatedEdgeFillings'] = JSON.stringify(edgeFillings);
    }
    else {
      let edgeFillings = JSON.parse(localStorage['savedEdgeFillings']);
      const index = edgeFillings.findIndex(edgeFilling => edgeFilling.id === lastEdgeFilling.get('id'));
      let paths = edgeFillings[index].paths;
      paths.push(path);
      localStorage['savedEdgeFillings'] = JSON.stringify(edgeFillings);
    }

    this.props.addPathToLastEdgeFilling(path);
  }

  async endEdgeFilling () {
    if (this.state.road_part === 0) {
      this.props.showNotice('Tieosaa ei ole annettu', 'Warning')
      return;
    }

    if (!this.state.confirmed) {
      if (!this.state.showAccuracyFixer) {
        if (!this.state.showAccuracyFixer && this.state.autoGPS && this.state.accuracy >= 20) {
          this.toggleAccuracyFixer(this.endEdgeFilling);
          return;
        }
      }
      else {
        this.setState({
          showAccuracyFixer: false
        });
      }
    }

    const roadNumber = this.props.selectedConstructionSite.get('road_number');
    const roadPart = this.state.road_part;
    const roadDistance = this.state.road_pole;

    try {
      if (!this.state.confirmed && !await testIsValidRoadInfo(roadNumber, roadPart, roadDistance)) {
        this.props.showConfirm(roadNumber + '/' + roadPart + '/' + roadDistance +
          ' Ei ole oikea tierekisteri. Haluatko jatkaa?', this.confirmRoadInfo.bind(null, this.endMacadam));
        return;
      }
    } catch (error) {
      console.log(error);
    }

    this.setState({ confirmed: false });

    const currentEdgeFilling = this.state.creatingEdgeFilling;

    if (currentEdgeFilling != null) {
      this.setState({ disableSubmit: true });

      let edgeFilling = {
        construction_site_id: this.props.selectedConstructionSite.get('id'),
        pit: this.state.pit,
        truck_mass: parseFloat(this.state.truckMass),
        notice: this.state.notice,
        date: currentEdgeFilling.date,
        width: currentEdgeFilling.width,
        height: currentEdgeFilling.height,
        variety: currentEdgeFilling.variety,
        variety_weight: currentEdgeFilling.varietyWeight
      };

      if (this.props.selectedTruck != null) {
        edgeFilling.truck_id = this.props.selectedTruck.get('id');
        edgeFilling.truck = { register_number: this.props.selectedTruck.get('register_number') };
      }

      const direction = this.getAutomaticDirection(currentEdgeFilling.roadPart, currentEdgeFilling.roadDistance,
        this.state.road_part, this.state.road_pole);

      let path = {
        road: currentEdgeFilling.roadNumber,
        start_part: currentEdgeFilling.roadPart,
        start_distance: currentEdgeFilling.roadDistance,
        end_part: this.state.road_part,
        end_distance: this.state.road_pole,
        roadway: this.state.roadway,
        direction: direction,
        date: currentEdgeFilling.date,
      };

      let newEdgeFilling;

      try {
        newEdgeFilling = await fetch('/edgefilling', 'POST', edgeFilling);
      } catch (error) {
        this.saveEdgeFilling(edgeFilling, path);
        edgeFilling.date = edgeFilling.date + 'Z';
      }

      if (newEdgeFilling != null) {
        path.paths_id = newEdgeFilling.paths_id;

        try {
          await fetch('/path', 'POST', path);
        } catch (error) {
          this.saveNewPathToLastEdgeFilling(path);
        }
      }

      this.props.showNotice('Kuorma lopetettu', 'Ok');
      this.props.selectTruck(null);

      this.setState({
        notice: '',
      });

      localStorage.removeItem('truck');
      localStorage.removeItem('notice');
      localStorage.removeItem('creatingEdgeFilling');

      try {
        await this.getEdgeFillings(this.props.selectedConstructionSite.get('id'), true);
      } catch (err) {
        edgeFilling.paths = [path];
        this.props.addEdgeFilling(edgeFilling);
      }

      this.setState({ creatingEdgeFilling: null });
    }
    else {
      this.setState({ disableSubmit: true });
      await this.makeEndForPath();
    }

    this.setState({ disableSubmit: false });
  }

  async makeEndForPath () {
    const direction = this.getAutomaticDirection(this.state.lastPath.get('start_part'), this.state.lastPath.get('start_distance'),
      this.state.road_part, this.state.road_pole);
    console.log(direction);

    const path = {
      end_part: this.state.road_part,
      end_distance: this.state.road_pole,
      direction: direction
    };

    const lastEdgeFilling = this.props.edgeFillings.last();

    if (!lastEdgeFilling.get('not_saved')) {
      try {
        const data = await fetch('/path/' + this.state.lastPath.get('id'), 'PATCH', path);
        this.props.showNotice('Lopetus paalu tehty', 'Ok');
        this.props.updateLastPath(lastEdgeFilling.get('id'), data);
      } catch (error) {
        this.updateLocallyLastPath(path.end_part, path.end_distance);
        this.props.showNotice('Lopetus paalu tallennettu paikallisesti', 'Warning');
      }
    }
    else {
      this.updateLocallyLastPath(path.end_part, path.end_distance);
      this.props.showNotice('Lopetus paalu tehty', 'Ok');
    }
  }

  async updateLocallyLastPath (endPart, endDistance) {
    let length = 0;

    if (endPart === this.state.lastPath.get('start_part')) {
      length = endDistance - this.state.lastPath.get('start_distance');
    }
    else {
      const url = ROAD_URL + '/muunna?tie=' + this.state.lastPath.get('road') +
        '&osa=' + this.state.lastPath.get('start_part') + '&etaisyys=' + this.state.lastPath.get('start_distance')
        + '&losa=' + endPart + '&let=' + endDistance;

      try {
        const data = await (await window.fetch(url).json());
        length = data['loppupiste']['pituus'];
      } catch (error) { }
    }

    const updatedPath = {
      id: this.state.lastPath.get('id'),
      road: this.state.lastPath.get('road'),
      start_part: this.state.lastPath.get('start_part'),
      start_distance: this.state.lastPath.get('start_distance'),
      end_part: endPart,
      end_distance: endDistance,
      length: length,
      roadway: this.state.lastPath.get('roadway'),
      direction: this.state.lastPath.get('direction'),
      date: this.state.lastPath.get('date')
    };

    const lastEdgeFilling = this.props.edgeFillings.last();

    if (!lastEdgeFilling.get('not_saved')) {
      if (localStorage['updatedEdgeFillings'] == null) {
        localStorage['updatedEdgeFillings'] = JSON.stringify([]);
      }

      let edgeFillings = JSON.parse(localStorage['updatedEdgeFillings']);
      const existingIndex = edgeFillings.findIndex(edgeFilling => edgeFilling.id === lastEdgeFilling.get('id'));

      if (existingIndex !== -1) {
        let paths = edgeFillings[existingIndex].paths;
        paths[paths.length - 1] = updatedPath;
      }
      else {
        let paths = [];

        lastEdgeFilling.get('paths').forEach(path => {
          paths.push({
            road: path.get('road'),
            start_part: path.get('start_part'),
            start_distance: path.get('start_distance'),
            end_part: path.get('end_part'),
            end_distance: path.get('end_distance'),
            length: path.get('length'),
            width: path.get('width'),
            area: path.get('area'),
            roadway: path.get('roadway'),
            direction: path.get('direction'),
            location_on_road: path.get('location_on_road'),
          });
        });

        paths[paths.length - 1] = updatedPath;

        const edgeFilling = {
          id: lastEdgeFilling.get('id'),
          paths: paths
        }

        edgeFillings.push(edgeFilling);
      }

      localStorage['updatedEdgeFillings'] = JSON.stringify(edgeFillings);
    }
    else {
      let edgeFillings = JSON.parse(localStorage['savedEdgeFillings']);
      const index = edgeFillings.findIndex(edgeFilling => edgeFilling.id === lastEdgeFilling.get('id'));
      let paths = edgeFillings[index].paths;
      paths[paths.length - 1] = updatedPath;
      localStorage['savedEdgeFillings'] = JSON.stringify(edgeFillings);
    }

    this.props.updateLastPath(lastEdgeFilling.get('id'), updatedPath);
  }

  async onSubmit () {
    if (this.state.width === 0 || this.state.width == null) {
      this.props.showNotice('Leveys ei voi olla 0', 'Warning')
      return;
    }

    if (this.state.height === 0 || this.state.height == null) {
      this.props.showNotice('Korkeus ei voi olla 0', 'Warning')
      return;
    }

    if (this.state.variety === '' || this.state.variety == null) {
      this.props.showNotice('Lajiketta ei ole valittu', 'Warning')
      return;
    }

    if (this.state.varietyWeight === 0 || this.state.varietyWeight == null) {
      this.props.showNotice('Lajikkeen ominaispaino ei voi olla 0', 'Warning')
      return;
    }

    if (this.props.selectedConstructionSite == null) {
      this.props.showNotice('Kohdetta ei ole valittu', 'Warning')
      return;
    }

    if (this.state.road_part === 0 || this.state.road_part == null || this.state.road_part === '') {
      this.props.showNotice('Tieosaa ei ole annettu', 'Warning')
      return;
    }

    if (this.state.road_pole == null || this.state.road_pole === '') {
      this.props.showNotice('Paalua ei ole annettu', 'Warning')
      return;
    }

    if (!this.state.confirmed) {
      if (!this.state.showAccuracyFixer) {
        if (!this.state.showAccuracyFixer && this.state.autoGPS && this.state.accuracy >= 20) {
          this.toggleAccuracyFixer(this.onSubmit);
          return;
        }
      }
      else {
        this.setState({
          showAccuracyFixer: false
        });
      }
    }

    const roadNumber = this.props.selectedConstructionSite.get('road_number');
    const roadPart = this.state.road_part;
    const roadDistance = this.state.road_pole;

    try {
      if (!this.state.confirmed && !await testIsValidRoadInfo(roadNumber, roadPart, roadDistance)) {
        this.props.showConfirm(roadNumber + '/' + roadPart + '/' + roadDistance +
          ' Ei ole oikea tierekisteri. Haluatko jatkaa?', this.confirmRoadInfo.bind(null, this.onSubmit));
        return;
      }
    } catch (error) {
      console.log(error);
    }

    this.setState({ confirmed: false });

    const date = new Date();
    const timezoneOffset = date.getTimezoneOffset() / 60;
    date.setHours(date.getHours() - timezoneOffset);

    const edgeFilling = {
      width: parseFloat(this.state.width),
      height: parseFloat(this.state.height),
      variety: this.state.variety,
      variety_weight: parseFloat(this.state.varietyWeight),
      constructionSiteId: this.props.selectedConstructionSite.get('id'),
      roadNumber: this.props.selectedConstructionSite.get('road_number'),
      roadPart: this.state.road_part,
      roadDistance: this.state.road_pole,
      date: date.toISOString().replace('Z', '')
    };

    this.setState({ creatingEdgeFilling: edgeFilling });

    localStorage['creatingEdgeFilling'] = JSON.stringify(edgeFilling);

    this.props.showNotice('Levitys aloitettu', 'Ok');
  }

  async getEdgeFillings (constructionSite, error = false) {
    this.setState({ loadingEdgeFillings: true });

    const allPaths = await fetch('/paths/edgefilling/site/' + constructionSite);

    if (!error && typeof (Storage) !== 'undefined') {
      if (localStorage['savedEdgeFillings'] != null &&
        JSON.parse(localStorage['savedEdgeFillings']).length !== 0) {
        await this.sendSavedEdgeFillings();
        return;
      }
      if (localStorage['updatedEdgeFillings'] != null &&
        JSON.parse(localStorage['updatedEdgeFillings']).length !== 0) {
        await this.sendUpdatedEdgeFillings();
        return;
      }
    }

    fetch('/edgefilling?site=' + constructionSite).then(async data => {
      for (let index in data) {
        let edgeFilling = data[index];

        if (edgeFilling.paths_id) {
          try {
            const paths = allPaths.filter(path => path.paths_id === edgeFilling.paths_id);
            edgeFilling.paths = paths;

            let distance = 0;

            for (let path of paths) {
              distance += path.length;
            }

            const mass = (edgeFilling.width / 1000) * (edgeFilling.height / 1000) *
              edgeFilling.variety_weight * distance / 1000;

            edgeFilling.distance = distance;
            edgeFilling.mass = mass;
          } catch (error) {
            edgeFilling.paths = [];
          }
        }
        else {
          edgeFilling.paths = [];
        }
      }

      if (localStorage['savedEdgeFillings'] != null && JSON.parse(localStorage['savedEdgeFillings']).length !== 0) {
        const savedEdgeFillings = JSON.parse(localStorage['savedEdgeFillings']).filter(
          edgeFilling => edgeFilling.construction_site_id === this.props.selectedConstructionSite.get('id'));
        data = data.concat(savedEdgeFillings);
      }

      if (localStorage['updatedEdgeFillings'] != null && JSON.parse(localStorage['updatedEdgeFillings']).length !== 0) {
        const updatedEdgeFillings = JSON.parse(localStorage['updatedEdgeFillings']).filter(
          edgeFilling => edgeFilling.construction_site_id === this.props.selectedConstructionSite.get('id'));
        updatedEdgeFillings.forEach(edgeFilling => {
          const index = data.findIndex(d => d.id === edgeFilling.id);
          if (index !== -1) data.splice(index, 1);
          data.push(edgeFilling);
        });
      }

      this.props.addEdgeFillings(data);
    }).catch(error => {
      console.log(error);
      let edgeFillings = [];

      if (localStorage['savedEdgeFillings'] != null && JSON.parse(localStorage['savedEdgeFillings']).length !== 0) {
        const savedEdgeFillings = JSON.parse(localStorage['savedEdgeFillings']).filter(
          edgeFilling => edgeFilling.construction_site_id === this.props.selectedConstructionSite.get('id'));
        edgeFillings = edgeFillings.concat(savedEdgeFillings);
      }

      if (localStorage['updatedEdgeFillings'] != null && JSON.parse(localStorage['updatedEdgeFillings']).length !== 0) {
        const updatedEdgeFillings = JSON.parse(localStorage['updatedEdgeFillings']).filter(
          edgeFilling => edgeFilling.construction_site_id === this.props.selectedConstructionSite.get('id'));
        updatedEdgeFillings.forEach(edgeFilling => {
          const index = edgeFillings.findIndex(d => d.id === edgeFilling.id);
          if (index !== -1) edgeFillings.splice(index, 1);
          edgeFillings.push(edgeFilling);
        });
      }

      this.props.addEdgeFillings(edgeFillings);
    })
      .then(() => {
        this.setState({ loadingEdgeFillings: false });
      })
  }

  goChangeEdgeFilling (edgeFillingId) {
    this.setState({ changingEdgeFilling: edgeFillingId });
  }

  clearChangeEdgeFilling () {
    this.setState({ changingEdgeFilling: null });
  }

  changeEdgeFilling (edgeFilling) {
    fetch('/edgefilling/' + edgeFilling.get('id'), 'PATCH', edgeFilling).then(async data => {
      this.props.showNotice('Reunantäyttö muokattu', 'Ok');

      if (data.paths_id) {
        data.paths = edgeFilling.get('paths');
      }

      this.props.changeEdgeFilling(data);
      this.clearChangeEdgeFilling();
    }).catch(error => {
      this.props.showMessage('Virhe', 'Reunantäytön muokkaus epäonnistui', 'Error');
    });
  }

  changeLocalEdgeFilling (edgeFilling) {
    let edgeFillings = JSON.parse(localStorage['savedEdgeFillings']);
    const index = edgeFillings.findIndex(e => e['id'] === this.state.changingMass.get('id'));
    edgeFillings[index] = edgeFilling;
    localStorage['savedEdgeFillings'] = JSON.stringify(edgeFillings);
    this.props.showNotice('Reunantäyttö muokattu', 'Ok');
    this.props.changeEdgeFilling(edgeFilling);
    this.clearChangeEdgeFilling();
  }

  confirmRemoveEdgeFilling (edgeFilling) {
    this.setState({ removingEdgeFilling: edgeFilling });
    this.props.showConfirm('Poistetaanko reunantäyttö?', this.removeEdgeFilling);
  }

  removeEdgeFilling () {
    fetch('/edgefilling/' + this.state.removingEdgeFilling + '/', 'DELETE').then(data => {
      this.props.showNotice('Reunantäyttö poistettu', 'Ok')
      this.props.removeEdgeFilling(this.state.removingEdgeFilling);
    }).catch(error => {
      this.props.showMessage('Virhe', 'Reunantäytön poisto epäonnistui', 'Error');
    });
  }

  removeLocalEdgeFilling () {
    // Without timer does not work. Error: (Reducers may not dispatch actions.)
    timer(0).then(() => {
      let edgeFillings = JSON.parse(localStorage['savedEdgeFillings']);
      edgeFillings = edgeFillings.filter(edgeFilling => edgeFilling['id'] !== this.state.removingEdgeFilling);
      localStorage['savedEdgeFillings'] = JSON.stringify(edgeFillings);
      this.props.showNotice('Murske poistettu', 'Ok')
      this.props.removeMacadam(this.state.removingEdgeFilling);
    });
  }

  confirmRemoveLocalEdgeFilling (edgeFillingId) {
    this.setState({ removingEdgeFilling: edgeFillingId });
    this.props.showConfirm('Poistetaanko reunantäyttö?', this.removeLocalEdgeFilling);
  }

  confirmRemoveLastPath (edgeFilling) {
    this.setState({ removingPathByEdgeFilling: edgeFilling });
    this.props.showConfirm('Poistetaanko viimeisin täyttö kuormasta?', this.removeLastPath);
  }

  removeLastPath () {
    fetch('/path/' + this.state.removingPathByEdgeFilling.get('paths').last().get('id'), 'DELETE').then(data => {
      this.props.showNotice('Täyttä poistettu', 'Ok')
      this.props.removeLastPath(this.state.removingPathByEdgeFilling.get('id'));
      this.setState({ lastPath: this.props.edgeFillings.last().get('paths').last() });
    }).catch(error => {
      console.log(error);
      this.props.showMessage('Virhe', 'Täytön poisto epäonnistui', 'Error');
    });
  }

  getDailyData (edgeFillings) {
    let dailyCount = 0;
    let dailyDistance = 0;
    let dailyMass = 0;
    let wholeDistance = 0;
    let wholeMass = 0;

    let now = new Date();
    now.setHours(now.getHours());

    let past12HoursFromNow = new Date(now);
    past12HoursFromNow.setHours(past12HoursFromNow.getHours() - 12);

    let newDay = false;

    for (let i = edgeFillings.size - 1; i >= 0; i--) {
      const edgeFilling = edgeFillings.get(i);
      const date = new Date(edgeFilling.get('date'));

      if (!newDay && date >= past12HoursFromNow &&
        date <= now) {
        dailyCount++;
        dailyMass += edgeFilling.get('mass');
        dailyDistance += edgeFilling.get('distance');
      }

      if (i !== 0) {
        let past6Hours = new Date(date);
        past6Hours.setHours(past6Hours.getHours() - 6);
        let beforeEdgeFillingDate = new Date(edgeFillings.get(i - 1).get('date'));
        beforeEdgeFillingDate.setHours(beforeEdgeFillingDate.getHours());
        if (past6Hours >= beforeEdgeFillingDate) {
          newDay = true;
        }
      }

      wholeMass += edgeFilling.get('mass');
      wholeDistance += edgeFilling.get('distance');
    }

    this.setState({
      dailyCount: dailyCount,
      dailyDistance: dailyDistance,
      dailyMass: dailyMass,
      wholeDistance: wholeDistance,
      wholeMass: wholeMass
    });
  }

  setView (view) {
    this.setState({ view: view });
  }

  goAddingPit () {
    const pit = prompt('Monttu:', '');
    if (pit == null || pit === '') return;
    else this.addPit(pit);;
  }

  addPit (pit) {
    let pits = [];

    if (typeof (Storage) !== 'undefined') {
      if (localStorage['pits'] == null) {
        localStorage['pits'] = JSON.stringify([]);
      }
      pits = JSON.parse(localStorage['pits']);

      if (pits.length === 3) {
        pits.splice(2, 1);
      }

      pits.splice(0, 0, pit);
      localStorage['pits'] = JSON.stringify(pits);
    }
    else {
      pits = this.state.pits;
      pits.push(pit);
    }

    this.setState({ pits: pits });
  }

  toggleAccuracyFixer (submit) {
    this.setState({
      accuracySubmit: submit,
      showAccuracyFixer: !this.state.showAccuracyFixer
    });
  }

  getLastEdgeFillingData (edgeFillings, creatingEdgeFilling) {
    let lastPart = 0;
    let lastPole = 0;
    let lastWidth = null;
    let lastHeight = null;
    let lastVariety = null;
    let lastVarietyWeight = null;
    let lastPit = null;
    let lastDirection = null;
    let lastRoadway = null;
    let lastPath = null;

    const lastEdgeFilling = edgeFillings.last();

    if (creatingEdgeFilling) {
      lastPart = creatingEdgeFilling.roadPart;
      lastPole = creatingEdgeFilling.roadDistance;
    }
    else if (lastEdgeFilling != null) {
      if (lastEdgeFilling.get('paths').size !== 0) {
        const path = lastEdgeFilling.get('paths').last();

        if (path.get('end_part')) {
          lastPart = path.get('end_part');
          lastPole = path.get('end_distance');
        }
        else {
          lastPart = path.get('start_part')
          lastPole = path.get('start_distance');
        }

        lastWidth = lastEdgeFilling.get('width');
        lastDirection = path.get('direction');
        lastRoadway = path.get('roadway');

        lastPath = path;
      }

      lastHeight = lastEdgeFilling.get('height');
      lastVariety = lastEdgeFilling.get('variety');
      lastVarietyWeight = lastEdgeFilling.get('variety_weight');
      lastPit = lastEdgeFilling.get('pit');
    }

    this.setState({
      lastPart: lastPart,
      lastPole: lastPole,
      lastWidth: lastWidth,
      lastHeight: lastHeight,
      lastVariety: lastVariety,
      lastVarietyWeight: lastVarietyWeight,
      lastPit: lastPit,
      lastDirection: lastDirection,
      lastRoadway: lastRoadway,
      lastPath: lastPath
    });
  }

  async getMapPaths (edgeFillings, site) {
    this.setState({ mapPaths: [] });

    let paths = [];
    let x = 0;
    let y = 0;
    let z = 0;
    let coordinateCount = 0;
    let zoom = null;
    let position = null;
    let allPoints = [];

    if (site != null) {
      try {
        allPoints = await fetch('/points/edgefilling/site/' + site);
      } catch (err) { }
    }

    for (let i = 0; i < edgeFillings.size; i++) {
      const edgeFilling = edgeFillings.get(i);

      for (let p = 0; p < edgeFilling.get('paths').size; p++) {
        const path = edgeFilling.get('paths').get(p);

        if (!path.get('start_latitude')) continue;

        const startLatitude = toRadians(path.get('start_latitude'));
        const startLongitude = toRadians(path.get('start_longitude'));
        x += Math.cos(startLatitude) * Math.cos(startLongitude);
        y += Math.cos(startLatitude) * Math.sin(startLongitude);
        z += Math.sin(startLatitude);
        coordinateCount++;

        let positions = [];

        if (path.get('end_latitude')) {
          const allPathPoint = allPoints.filter(point => point.path_id === path.get('id'));

          if (allPathPoint.length !== 0) {
            allPathPoint.forEach(point => {
              positions.push([point.latitude, point.longitude])
            });
            if (allPathPoint[allPathPoint.length - 1].road_distance !== path.get('end_distance')) {
              positions.push([path.get('end_latitude'), path.get('end_longitude')])
            }
          }
          else {
            positions = [[path.get('start_latitude'), path.get('start_longitude')],
            [path.get('end_latitude'), path.get('end_longitude')]];
          }

          const endLatitude = toRadians(path.get('end_latitude'));
          const endLongitude = toRadians(path.get('end_longitude'));
          x += Math.cos(endLatitude) * Math.cos(endLongitude);
          y += Math.cos(endLatitude) * Math.sin(endLongitude);
          z += Math.sin(endLatitude);
          coordinateCount++;
        }
        else {
          positions = [[path.get('start_latitude'), path.get('start_longitude')],
          [path.get('start_latitude'), path.get('start_longitude')]]
        }

        if (path.get('direction') === 2) {
          if (path.get('start_part') > path.get('end_part') ||
            path.get('start_distance') > path.get('end_distance')) {
            positions.reverse();
          }
          positions = await this.get2DirectionPath(positions);
        }

        paths[path.get('id')] = positions;
      }
    }

    if (coordinateCount !== 0) {
      zoom = 15;

      x = x / coordinateCount;
      y = y / coordinateCount;
      z = z / coordinateCount;

      const centralLongitude = Math.atan2(y, x);
      const centralSquareRoot = Math.sqrt(x * x + y * y);
      const centralLatitude = Math.atan2(z, centralSquareRoot);

      position = [centralLatitude * 180 / Math.PI, centralLongitude * 180 / Math.PI];
    }
    else if (this.props.yourLaditude != null) {
      zoom = 15;
      position = [this.props.yourLaditude, this.props.yourLongitude];
    }

    this.setState({
      mapPaths: paths,
      mapZoom: zoom,
      mapPosition: position
    });
  }

  async get2DirectionPath (path) {
    let newPath = []
    let lastAngle;

    if (path.length > 1 && path[0][0] !== path[1][0]) {
      for (let index in path) {
        index = parseInt(index, 10);
        const point = path[index];

        if (index !== path.length - 1) {
          const point2 = path[index + 1];
          lastAngle = this.getOffSetAngle(point[0], point[1], point2[0], point2[1]);
        }

        const newCoordinate = this.getNewCoordinatesByAngle(lastAngle, point[0], point[1])
        newPath.push(newCoordinate)
      }
    }
    else {
      try {
        const converted = toETRSTM35FIN(path[0][0], path[0][1]);
        let url = ROAD_URL + '/muunna?y=' + converted.y + '&x=' + converted.x;
        let roadData = await (await window.fetch(url)).json();
        const roadNumber = roadData['tie'];
        const roadPart = roadData['osa'];
        let roadDistance = roadData['etaisyys'];
        const anotherPointDistance = 10;

        if (roadDistance < anotherPointDistance) {
          roadDistance = roadDistance + anotherPointDistance;
        }
        else {
          roadDistance = roadDistance - anotherPointDistance;
        }

        url = ROAD_URL + '/muunna?tie=' + roadNumber + '&osa=' + roadPart + '&etaisyys=' + roadDistance;
        roadData = await (await window.fetch(url)).json();
        const x = roadData['alkupiste']['tieosoitteet'][0]['point']['x'];
        const y = roadData['alkupiste']['tieosoitteet'][0]['point']['y'];
        const anotherCoordinates = toWGS84(y, x);

        const angle = this.getOffSetAngle(anotherCoordinates.latitude, anotherCoordinates.longitude,
          path[0][0], path[0][1]);

        const newCoordinate = this.getNewCoordinatesByAngle(angle, path[0][0], path[0][1])
        newPath = [newCoordinate, newCoordinate]
      } catch (error) {
        return path;
      }
    }

    return newPath;
  }

  getOffSetAngle (lat1, lon1, lat2, lon2) {
    const dLon = lon2 - lon1;
    const y = Math.sin(dLon) * Math.cos(lat2);
    const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) *
      Math.cos(lat2) * Math.cos(dLon);
    let angle = Math.atan2(y, x);
    angle = toDegrees(angle);
    angle = (angle + 360 - 90) % 360;
    return toRadians(angle);
  }

  getNewCoordinatesByAngle (angle, latitude, longitude) {
    const R = 6378100; // Radius of the Earth
    const distanceBetween = 5;

    const lat1 = toRadians(latitude);
    const lon1 = toRadians(longitude);

    let newLatitude = Math.asin(Math.sin(lat1) * Math.cos(distanceBetween / R) +
      Math.cos(lat1) * Math.sin(distanceBetween / R) * Math.cos(angle));

    let newLongitude = lon1 + Math.atan2(Math.sin(angle) * Math.sin(distanceBetween / R) * Math.cos(lat1),
      Math.cos(distanceBetween / R) - Math.sin(lat1) * Math.sin(newLatitude));

    newLatitude = toDegrees(newLatitude);
    newLongitude = toDegrees(newLongitude);

    return [newLatitude, newLongitude];
  }

  async newPath () {
    if (this.props.selectedConstructionSite == null) {
      this.props.showNotice('Kohdetta ei ole valittu', 'Warning');
      return;
    }

    if (this.state.lastPath == null) {
      this.props.showNotice('Aikasempaa kuormaa ei ole', 'Warning');
      return;
    }

    if (this.state.width === "0" || this.state.width === "" || this.state.width === 0) {
      this.props.showNotice('Tien leveyttä ei ole annettu', 'Warning');
      return;
    }

    if (this.state.road_part === 0 || this.state.road_part === '') {
      this.props.showNotice('Tieosaa ei ole annettu', 'Warning');
      return;
    }

    if (this.state.road_pole === null || this.state.road_pole === '') {
      this.props.showNotice('Paalua ei ole annettu', 'Warning');
      return;
    }

    if (!this.state.confirmed) {
      if (!this.state.showAccuracyFixer) {
        if (!this.state.showAccuracyFixer && this.state.autoGPS && this.state.accuracy >= 20) {
          this.toggleAccuracyFixer(this.newPath);
          return;
        }
      }
      else {
        this.setState({
          showAccuracyFixer: false
        });
      }
    }

    const roadNumber = this.props.selectedConstructionSite.get('road_number');
    const roadPart = this.state.road_part;
    const roadDistance = this.state.road_pole;

    try {
      if (!this.state.confirmed && !await testIsValidRoadInfo(roadNumber, roadPart, roadDistance)) {
        this.props.showConfirm(roadNumber + '/' + roadPart + '/' + roadDistance +
          ' Ei ole oikea tierekisteri. Haluatko jatkaa?', this.confirmRoadInfo.bind(null, this.newPath));
        return;
      }
    } catch (error) {
      console.log(error);
    }

    this.setState({
      confirmed: false,
      disableNewPath: true
    });

    if (!this.state.lastPath.get('end_part')) {
      await this.makeEndForPath();
    }

    let date = new Date();
    date.setHours(date.getHours());

    let path = {
      paths_id: this.state.lastPath.get('paths_id'),
      road: roadNumber,
      start_part: roadPart,
      start_distance: roadDistance,
      roadway: this.state.roadway,
      direction: this.state.direction,
      date: date.toISOString().replace('Z', ''),
      width: this.state.width,
    };

    fetch('/path', 'POST', path).then(async data => {
      this.props.showNotice('Uusi aloitus tehty', 'Ok');
      this.props.addPathToLastEdgeFilling(data);
    }).catch(error => {
      this.saveNewPathToLastEdgeFilling(path);
      this.props.showNotice('Uusi aloitus tallenettu paikallisesti', 'Warning');
    }).then(() => {
      this.setState({ disableNewPath: false });
    });
  }

  async getPaths (id) {
    const paths = await fetch('/paths/' + id);
    return paths;
  }

  getAutomaticDirection (lastRoadPart, lastRoadDistance, roadPart, roadDistance) {
    let direction;

    console.log(lastRoadDistance)
    console.log(roadDistance)

    if (lastRoadPart < roadPart ||
      (lastRoadPart === roadPart && lastRoadDistance < roadDistance)) {
      direction = 1;
    }
    else {
      direction = 2;
    }

    this.setState({ direction: direction });
    return direction;
  }

  render () {
    return (
      <div>
        <div className="container">
          <h1>Reunantäyttö</h1>
          <div className='button-area'>
            <button className='button-view' onClick={this.setView.bind(this, 0)}
              disabled={this.state.view === 0}>
              Perustiedot
            </button>
            <button className='button-view' onClick={this.setView.bind(this, 1)}
              disabled={this.state.view === 1}>
              Levitystiedot
            </button>
            <button className='button-view' onClick={this.setView.bind(this, 2)}
              disabled={this.state.view === 2}>
              Seurantatiedot
            </button>
            <button className='button-view' onClick={this.setView.bind(this, 3)}
              disabled={this.state.view === 3}>
              Kartta
            </button>
          </div>
          <View
            view={this.state.view} changeState={this.changeState} height={this.state.height}
            width={this.state.width} varietyWeight={this.state.varietyWeight}
            variety={this.state.variety} pit={this.state.pit} pits={this.state.pits}
            roadway={this.state.roadway} direction={this.state.direction}
            startRoadPart={this.state.road_part}
            startRoadPole={this.state.road_pole}
            road={this.props.selectedConstructionSite ? this.props.selectedConstructionSite.get('road_number') : '-'}
            newEdgeFilling={this.onSubmit} useLocation={this.useLocation}
            mass={this.state.truckMass}
            notice={this.state.notice}
            dailyMass={this.state.dailyMass} dailyDistance={this.state.dailyDistance}
            wholeDistance={this.state.wholeDistance} wholeMass={this.state.wholeMass}
            dailyCount={this.state.dailyCount}
            edgeFillings={this.props.edgeFillings} selectedConstructionSite={this.props.selectedConstructionSite}
            loading={this.state.loadingEdgeFillings} confirmRemoveEdgeFilling={this.confirmRemoveEdgeFilling}
            confirmRemoveLocalEdgeFilling={this.confirmRemoveLocalEdgeFilling}
            removeLastPath={this.confirmRemoveLastPath}
            location_road_number={this.state.location_road_number} location_road_part={this.state.location_road_part}
            location_road_pole={this.state.location_road_pole} accuracy={this.state.accuracy}
            locationTime={this.state.locationTime} latitude={this.state.latitude} longitude={this.state.longitude}
            mapPaths={this.state.mapPaths} mapZoom={this.state.mapZoom} mapPosition={this.state.mapPosition}
            showMessage={this.props.showMessage} changeEdgeFilling={this.goChangeEdgeFilling}
            goAddingPit={this.goAddingPit} autoGPS={this.state.autoGPS}
            endEdgeFilling={this.endEdgeFilling}
            creatingEdgeFilling={this.props.selectedConstructionSite && ((this.state.creatingEdgeFilling &&
              this.state.creatingEdgeFilling.constructionSiteId === this.props.selectedConstructionSite.get('id'))
              || (this.state.lastPath && this.state.lastPath.get('end_part') == null))}
            disableSubmit={this.state.disableSubmit}
            lastPart={this.state.lastPart}
            lastPole={this.state.lastPole}
            lastHeight={this.state.lastHeight}
            lastWidth={this.state.lastWidth}
            lastVariety={this.state.lastVariety}
            lastVarietyWeight={this.state.lastVarietyWeight}
            lastPit={this.state.lastPit}
            lastDirection={this.state.lastDirection}
            lastRoadway={this.state.lastRoadway}
            paths={this.state.paths}
            newPath={this.newPath}
            disableNewPath={this.state.disableNewPath}
            organizationId={this.props.organizationId}
            store={this.props.store}
          />
          <ChangeEdgeFillingView edgeFilling={this.state.changingEdgeFilling} clear={this.clearChangeEdgeFilling}
            changeEdgeFilling={this.changeEdgeFilling} changeLocalEdgeFilling={this.changeLocalEdgeFilling}
            goAddingPit={this.goAddingPit} pits={this.state.pits} />
          <AccuracyFixer show={this.state.showAccuracyFixer} toggle={this.toggleAccuracyFixer}
            submit={!this.state.creatingEdgeFilling ? this.onSubmit : this.endEdgeFilling} accuracy={this.state.accuracy} />
          <div id='top-info'>
            {this.state.creatingEdgeFilling ?
              <div>
                <span className='distance-info'>
                  Aloitus: <strong>{this.state.creatingEdgeFilling.roadPart + ' / ' +
                    this.state.creatingEdgeFilling.roadDistance}</strong>
                </span>
                {this.state.autoGPS ?
                  <div>
                    <span className='distance-info'>
                      Matka: <strong>{this.state.traveledDistance} m</strong>
                    </span>
                    <span className='distance-info'>
                      Massa: <strong>{this.state.currentMassUsage} tonnia</strong>
                    </span>
                  </div>
                  : null
                }
              </div>
              :
              null
            }
            <span className='distance-info'>
              Korkeus: <strong>{this.state.height} mm</strong>
            </span>
            <span className='distance-info'>
              Leveys: <strong>{this.state.width} mm</strong>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  edgeFillings: state.edgeFilling.get('edgeFillings'),
  selectedContract: state.contractSelect.get('selectedContract'),
  selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
  selectedTruck: state.truckSelect.get('selectedTruck'),
  organizationId: state.login.get('user') ? state.login.get('user').get('organizationId') : null,
}), {
  addEdgeFilling, addEdgeFillings, removeEdgeFilling, clearEdgeFillings,
  changeEdgeFilling, updateLastPath, addPathToLastEdgeFilling, removeLastPath,
  showNotice, showMessage, showConfirm, selectTruck
})(EdgeFillingNew);
