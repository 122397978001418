import React from 'react';
import { connect } from 'react-redux';
import { showNotice, selectContructionSite, selectContract } from 'components';
import { fetch } from '../utils';
import { XYPlot, YAxis, LineSeries } from 'react-vis';
import "../../../node_modules/react-vis/dist/style.css";
import './ConstructionSiteLatestSelect.css'

class ConstructionSiteLatestSelect extends React.Component {
  constructor(props) {
    super(props);

    this.site = null;

    this.state = {
      constructionSites: [],
      graphReady: false,
      firstTime: true,
      draw: false,
      loading: true
    };

    this.selectContructionSite = this.selectContructionSite.bind(this);
  }

  async componentWillMount() {
    const url = '/' + this.props.get;
    let count = 0;
    let constructionSites = [];

    try {
      const sites = await fetch('/constructionsites');
      const targetCount = sites.length < 5 ? sites.length : 5;
      const data = await fetch(url);

      for (let d = 0; d < data.length; d++) {
        const mass = data[d];

        if (count === 0) {
          constructionSites.push(mass['construction_site']['id']);
          count++;
        }
        else if (mass['construction_site']['id'] !== constructionSites[count - 1]) {
          let same = false;

          for (let i = count - 2; i >= 0; i--) {
            if (mass['construction_site']['id'] === constructionSites[i]) {
              same = true;
              break;
            }
          }

          if (same) continue;

          constructionSites.push(mass['construction_site']['id']);
          count++;

          if (count === targetCount) break;
        }
      }

      let gotSites = [];

      for (let i = 0; i < count; i++) {
        const site = sites.find(site => site['id'] === constructionSites[i]);
        gotSites.push(site);
      }

      if (this.props.graph) {
        for (let i = 0; i < gotSites.length; i++) {
          const site = gotSites[i];
          let graphData = [];
          let graphData2 = [];

          const objects = (await fetch(url + '?site=' + site['id'])).reverse();
          for (let index = 0; index < objects.length; index++) {
            const object = objects[index];
            if (this.props.graph[0] === 'calculated_kg_per_square') {
              if (object['area'] !== 0) {
                object['calculated_kg_per_square'] = object['mass'] * 1000 / object['area'];
              }
            }
            if (object[this.props.graph[0]] != null) {
              graphData.push({x: index, y: object[this.props.graph[0]]});
              graphData2.push({x: index, y: object[this.props.graph[1]]});
            }
          }

          const propertyName = 'graphData-' + site['id'];
          const propertyName2 = 'graphData2-' + site['id'];

          this[propertyName] = graphData;
          this[propertyName2] = graphData2;
        }
      }

      this.setState({
        constructionSites: gotSites,
        loading: false
      });
    } catch(error) {
      console.log('Getting five last construction sites failed: ' + error, 'Error');
    }
  }

  componentWillUpdate(props, state) {
    if (this.site != null && props.constructionSites !== this.props.constructionSites) {
      props.selectContructionSite(this.site);
      localStorage.site = this.site['id'];
    }
  }

  selectContructionSite(site) {
    this.site = site;
    const contract = site['contract']['id'];
    this.props.selectContract(contract);
    this.props.selectContructionSite(site);
    localStorage.contract = contract;
  }

  render() {
    if (this.state.loading) return <div className='loader'></div>;

    if (this.state.constructionSites.length === 0) return null;

    return (
      <div>
        <h4>Viimeisimmät kohteet</h4>
        <div id='construction-site-container'>
          { this.state.constructionSites.map(site => {
            const selected = this.props.selectedConstructionSite ? this.props.selectedConstructionSite.get('id') === site['id'] : false;
            return (
              <div key={site['name']} className='construction-site-select-container'
                    onClick={selected ? null : this.selectContructionSite.bind(null, site)}
                   style={{margin: 'auto'}}>
                <div className={'construction-site-select' + (selected ? ' selected' : '')}>
                  {site['contract']['name']}
                  <br/>
                  {site['name']}
                </div>
                { this.props.graph ?
                  <XYPlot height={150} width={225}>
                    <YAxis />
                    <LineSeries color="blue" data={this['graphData-' + site['id']]} />
                    <LineSeries color="green" data={this['graphData2-' + site['id']]} />
                  </XYPlot>
                : null
                }
              </div>
            );
          })
          }
        </div>
      </div>
    );
  };
}

export default connect(state => ({
  constructionSites: state.constructionSiteSelect.get('constructionSites'),
  selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
}), { showNotice, selectContructionSite, selectContract })(ConstructionSiteLatestSelect);
