import React from 'react';
import { connect } from 'react-redux';
import ConstructionSiteLatestSelect from './ConstructionSiteLatestSelect';
import { ContractSelect, ConstructionSiteSelect, showMessage, List, Excel } from 'components';
import MapView from './Components';
import { addEdgeFilling, addEdgeFillings, removeEdgeFilling, clearEdgeFillings, changeEdgeFilling } from './Actions';
import { fetch, Socket, toRadians, toDegrees, toETRSTM35FIN, toWGS84, ROAD_URL } from '../utils';
import './EdgeFilling.css';
import TimeRange from '../timeRange/TimeRange'

const MapOrList = props => {
  var edgeFillings = props.edgeFillings;

  if (props.site === null) {
    edgeFillings = props.edgeFillings.clear();
  }

  if (props.state === 0) return <MapView edgeFillings={props.edgeFillings}
    site={props.site}
    mapPaths={props.mapPaths}
    mapZoom={props.mapZoom}
    mapPosition={props.mapPosition} />;

  let totalTruckMass = 0;
  edgeFillings.map(macadam => {
    totalTruckMass += macadam.get('truck_mass');
    return null;
  });

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>
              Kuorma tonnit
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>Yhteensä:</b>
            </td>
            <td>
              {Math.round(totalTruckMass * 10) / 10}
            </td>
          </tr>
        </tbody>
      </table>
      <List emptyText={'Ei murskeita'}
        header={['Aika', 'Aloitus-lopetus', 'Suunta / ajorata', 'Pituus', 'Ajoneuvo', 'Kuorman koko',
          'Korkeus (mm)', 'Lajike', 'Lajike ominaispaino', 'Monttu', 'Huomiot']}
        fields={['date#time', 'roadPaths', 'roadPositions', 'length',
          'truck.register_number', 'truck_mass', 'height', 'variety', 'variety_weight', 'pit', 'notice']}
        data={edgeFillings} />
    </div>
  );
};


class EdgeFillings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      state: 0,
      loading: false,
      makeExcel: false,
      mapPaths: []
    };

    this.changeState = this.changeState.bind(this);
    this.toggleMakeExcel = this.toggleMakeExcel.bind(this);
  }

  componentDidMount () {
    if (this.socket == null && typeof (WebSocket) !== 'undefined') {
      this.socket = Socket('/data/macadam');
      this.socket.onmessage = function (e) {
        const data = JSON.parse(e.data);
        if (data['operation'] === 'create') {
          if (this.props.selectedConstructionSite.get('id') !== data.model.constructionSiteId) {
            return;
          }
          this.props.addEdgeFilling(data.model);
        }
        else if (data['operation'] === 'update') {
          if (this.props.selectedConstructionSite.get('id') !== data.model.construction_site.id) {
            return;
          }
          this.props.changeEdgeFilling(data.model);
        }
        else if (data['operation'] === 'delete') {
          this.props.removeEdgeFilling(data.model);
        }
      }.bind(this)
    }
    if (!this.props.selectedConstructionSite) return;
    if (this.props.timeRangeStart !== '' && this.props.timeRangeEnd !== '') {
      this.getEdgeFillings(this.props.selectedConstructionSite.get('id'), this.props.timeRangeStart, this.props.timeRangeEnd);
    }
    else {
      this.getEdgeFillings(this.props.selectedConstructionSite.get('id'));
    }
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.edgeFillings !== nextProps.edgeFillings) {
      this.getMapPaths(nextProps.edgeFillings, nextProps.selectedConstructionSite ?
        nextProps.selectedConstructionSite.get('id') : null);
    }
    if (this.props.selectedConstructionSite === nextProps.selectedConstructionSite &&
      this.props.timeRangeStart === nextProps.timeRangeStart &&
      this.props.timeRangeEnd === nextProps.timeRangeEnd) return;
    if (nextProps.selectedContract == null || nextProps.selectedConstructionSite == null) {
      this.props.clearEdgeFillings();
      return;
    }
    if (nextProps.timeRangeStart !== '' && nextProps.timeRangeEnd !== '') {
      this.getEdgeFillings(nextProps.selectedConstructionSite.get('id'), nextProps.timeRangeStart, nextProps.timeRangeEnd);
    }
    else {
      this.getEdgeFillings(nextProps.selectedConstructionSite.get('id'));
    }
  }

  componentWillUnmount () {
    if (this.socket != null) this.socket.close();
  }

  async getEdgeFillings (site, startTime, endTime) {
    this.setState({ loading: true });

    const allPaths = await fetch('/paths/edgefilling/site/' + site);

    let url;

    if (startTime != null) {
      url = '/edgefilling?site=' + site + '&timestart=' + startTime
        + '&timeend=' + endTime;
    }
    else {
      url = '/edgefilling?site=' + site;
    }

    fetch(url).then(data => {
      for (let index in data) {
        let edgeFilling = data[index];

        if (edgeFilling.paths_id) {
          try {
            const paths = allPaths.filter(path => path.paths_id === edgeFilling.paths_id);
            let roadPaths = '';
            let roadPositions = '';
            let length = 0;

            for (let p in paths) {
              const path = paths[p];

              roadPaths += path.start_part + ' / ' + path.start_distance + ' - ' +
                (path.end_part ? (path.end_part + ' / ' + path.end_distance) + ' , ' : '');
              roadPositions += path.direction + ' / ' + path.roadway +
                ' , ';
              length += path.length;
            }

            edgeFilling.roadPaths = roadPaths.substring(0, roadPaths.length - 3);
            edgeFilling.roadPositions = roadPositions.substring(0, roadPositions.length - 3);
            edgeFilling.length = length;
            edgeFilling.paths = paths;
          } catch (error) {
            edgeFilling.paths = [];
          }
        }
        else {
          edgeFilling.paths = [];
        }
      }

      this.props.addEdgeFillings(data);
      this.setState({
        loading: false
      });
    }).catch(error => {
      console.log(error)
      this.props.showMessage('Virhe', 'Palvelimeen ei saatu yhteyttä', 'Error');
    });
  }

  changeState (state) {
    this.setState({
      state: state
    });
  }

  toggleMakeExcel () {
    this.setState({
      makeExcel: !this.state.makeExcel
    })
  }

  async getMapPaths (edgeFillings, site) {
    this.setState({ mapPaths: [] });

    let paths = [];
    let x = 0;
    let y = 0;
    let z = 0;
    let coordinateCount = 0;
    let zoom = null;
    let position = null;
    let allPoints = [];

    if (site != null) {
      try {
        allPoints = await fetch('/points/macadam/site/' + site);
      } catch (err) { }
    }

    for (let i = 0; i < edgeFillings.size; i++) {
      const mass = edgeFillings.get(i);

      for (let p = 0; p < mass.get('paths').size; p++) {
        const path = mass.get('paths').get(p);

        if (!path.get('start_latitude')) continue;

        const startLatitude = toRadians(path.get('start_latitude'));
        const startLongitude = toRadians(path.get('start_longitude'));
        x += Math.cos(startLatitude) * Math.cos(startLongitude);
        y += Math.cos(startLatitude) * Math.sin(startLongitude);
        z += Math.sin(startLatitude);
        coordinateCount++;

        let positions = [];

        if (path.get('end_latitude')) {
          const allPathPoint = allPoints.filter(point => point.path_id === path.get('id'));

          if (allPathPoint.length !== 0) {
            allPathPoint.forEach(point => {
              positions.push([point.latitude, point.longitude])
            });
            if (allPathPoint[allPathPoint.length - 1].road_distance !== path.get('end_distance')) {
              positions.push([path.get('end_latitude'), path.get('end_longitude')])
            }
          }
          else {
            positions = [[path.get('start_latitude'), path.get('start_longitude')],
            [path.get('end_latitude'), path.get('end_longitude')]];
          }

          const endLatitude = toRadians(path.get('end_latitude'));
          const endLongitude = toRadians(path.get('end_longitude'));
          x += Math.cos(endLatitude) * Math.cos(endLongitude);
          y += Math.cos(endLatitude) * Math.sin(endLongitude);
          z += Math.sin(endLatitude);
          coordinateCount++;
        }
        else {
          positions = [[path.get('start_latitude'), path.get('start_longitude')],
          [path.get('start_latitude'), path.get('start_longitude')]]
        }

        if (path.get('direction') === 2) {
          if (path.get('start_part') > path.get('end_part') ||
            path.get('start_distance') > path.get('end_distance')) {
            positions.reverse();
          }
          positions = await this.get2DirectionPath(positions);
        }

        paths[path.get('id')] = positions;
      }
    }

    if (coordinateCount !== 0) {
      zoom = 15;

      x = x / coordinateCount;
      y = y / coordinateCount;
      z = z / coordinateCount;

      const centralLongitude = Math.atan2(y, x);
      const centralSquareRoot = Math.sqrt(x * x + y * y);
      const centralLatitude = Math.atan2(z, centralSquareRoot);

      position = [centralLatitude * 180 / Math.PI, centralLongitude * 180 / Math.PI];
    }
    else {
      zoom = 6;
      position = [64.1, 26.5];
    }

    this.setState({
      mapPaths: paths,
      mapZoom: zoom,
      mapPosition: position
    });
  }

  async get2DirectionPath (path) {
    let newPath = []
    let lastAngle;

    if (path.length > 1 && path[0][0] !== path[1][0]) {
      for (let index in path) {
        index = parseInt(index, 10);
        const point = path[index];

        if (index !== path.length - 1) {
          const point2 = path[index + 1];
          lastAngle = this.getOffSetAngle(point[0], point[1], point2[0], point2[1]);
        }

        const newCoordinate = this.getNewCoordinatesByAngle(lastAngle, point[0], point[1])
        newPath.push(newCoordinate)
      }
    }
    else {
      try {
        const converted = toETRSTM35FIN(path[0][0], path[0][1]);
        let url = ROAD_URL + '/muunna?y=' + converted.y + '&x=' + converted.x;
        let roadData = await (await window.fetch(url)).json();
        const roadNumber = roadData['tie'];
        const roadPart = roadData['osa'];
        let roadDistance = roadData['etaisyys'];
        const anotherPointDistance = 10;

        if (roadDistance < anotherPointDistance) {
          roadDistance = roadDistance + anotherPointDistance;
        }
        else {
          roadDistance = roadDistance - anotherPointDistance;
        }

        url = ROAD_URL + '/muunna?tie=' + roadNumber + '&osa=' + roadPart + '&etaisyys=' + roadDistance;
        roadData = await (await window.fetch(url)).json();
        const x = roadData['alkupiste']['tieosoitteet'][0]['point']['x'];
        const y = roadData['alkupiste']['tieosoitteet'][0]['point']['y'];
        const anotherCoordinates = toWGS84(y, x);

        const angle = this.getOffSetAngle(anotherCoordinates.latitude, anotherCoordinates.longitude,
          path[0][0], path[0][1]);

        const newCoordinate = this.getNewCoordinatesByAngle(angle, path[0][0], path[0][1])
        newPath = [newCoordinate, newCoordinate]
      } catch (error) {
        return path;
      }
    }

    return newPath;
  }

  getOffSetAngle (lat1, lon1, lat2, lon2) {
    const dLon = lon2 - lon1;
    const y = Math.sin(dLon) * Math.cos(lat2);
    const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) *
      Math.cos(lat2) * Math.cos(dLon);
    let angle = Math.atan2(y, x);
    angle = toDegrees(angle);
    angle = (angle + 360 - 90) % 360;
    return toRadians(angle);
  }

  getNewCoordinatesByAngle (angle, latitude, longitude) {
    const R = 6378100; // Radius of the Earth
    const distanceBetween = 5;

    const lat1 = toRadians(latitude);
    const lon1 = toRadians(longitude);

    let newLatitude = Math.asin(Math.sin(lat1) * Math.cos(distanceBetween / R) +
      Math.cos(lat1) * Math.sin(distanceBetween / R) * Math.cos(angle));

    let newLongitude = lon1 + Math.atan2(Math.sin(angle) * Math.sin(distanceBetween / R) * Math.cos(lat1),
      Math.cos(distanceBetween / R) - Math.sin(lat1) * Math.sin(newLatitude));

    newLatitude = toDegrees(newLatitude);
    newLongitude = toDegrees(newLongitude);

    return [newLatitude, newLongitude];
  }

  render () {
    let id = null;
    let name = null;

    if (this.props.selectedConstructionSite != null) {
      id = this.props.selectedConstructionSite.get('id');
      name = this.props.selectedConstructionSite.get('name');
    }

    return (
      <div>
        <div className="center">
          <h1>Reunantäytöt</h1>
          <ConstructionSiteLatestSelect get='edgefilling' />
        </div>
        <br />
        <div className='container'>
          <div className="row">
            <div className="column">
              <ContractSelect store={this.props.store} />
            </div>
            <div className="column">
              <ConstructionSiteSelect store={this.props.store} />
            </div>
          </div>
          <div className="row">
            <div className="column">
              <TimeRange />
            </div>
            <div className="column">
              <button onClick={this.toggleMakeExcel} disabled={id == null || this.props.edgeFillings.size === 0}>Luo raportti</button>
            </div>
          </div>
        </div>
        <div className='wide-area'>
          <fieldset id="data">
            <legend>
              <div className={"state" + (this.state.state === 0 ? ' selected' : '')}
                onClick={this.state.state === 0 ? null : this.changeState.bind(null, 0)}>
                Kartta
              </div>
              <div className={"state" + (this.state.state === 1 ? ' selected' : '')}
                onClick={this.state.state === 1 ? null : this.changeState.bind(null, 1)}>
                Lista
              </div>
            </legend>
            <MapOrList state={this.state.state} edgeFillings={this.props.edgeFillings} site={this.props.selectedConstructionSite}
              loading={this.state.loading} page={this.state.page} changePage={this.changePage}
              mapPaths={this.state.mapPaths} mapZoom={this.state.mapZoom}
              mapPosition={this.state.mapPosition} />
          </fieldset>
          {this.state.loading ? <div className="main loader" /> : null}
        </div>
        {name != null ?
          <Excel show={this.state.makeExcel} toggle={this.toggleMakeExcel}
            name={name.length > 31 ? id.toString() : name}
            headers={['Kohde', 'Aika', 'Tienumero', 'Aloitus - lopetus', 'Suunta / ajorata', 'Pituus (m)',
              'Rekka', 'Kuorman massa', 'Korkeus (mm)', 'Lajike', 'Lajike ominaispaino', 'Monttu', 'Huomiot']}
            dataHeaders={['construction_site.name', 'date', 'roadPaths', 'roadPositions', 'length',
              'truck.register_number', 'truck_mass', 'height', 'variety', 'variety_weight', 'pit', 'notice']}
            timeField={'date'}
            data={this.props.edgeFillings} />
          : null
        }
      </div>
    );
  }
}

export default connect(state => ({
  selectedContract: state.contractSelect.get('selectedContract'),
  selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
  timeRangeStart: state.timeRange.get('startTime'),
  timeRangeEnd: state.timeRange.get('endTime'),
  edgeFillings: state.edgeFilling.get('edgeFillings'),
}), {
  addEdgeFilling, addEdgeFillings, removeEdgeFilling, clearEdgeFillings,
  changeEdgeFilling, showMessage
})(EdgeFillings);
