import { fromJS } from 'immutable';

const initialState = fromJS({
  edgeFillings: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_EDGE_FILLINGS': {
      return state.set('edgeFillings', fromJS([]));
    }

    case 'ADD_EDGE_FILLINGS': {
      const sortedEdgeFillings = fromJS(action.edgeFillings).sort((a, b) => {
        return new Date(a.get('date')) - new Date(b.get('date'));
      });
      return state.set('edgeFillings', sortedEdgeFillings);
    }

    case 'REMOVE_EDGE_FILLING': {
      const removableEdgeFilling = state.get('edgeFillings').findIndex(edgeFilling => edgeFilling.get('id') === fromJS(action.edgeFilling));
      if (removableEdgeFilling !== -1) {
        return state.set('edgeFillings', state.get('edgeFillings').delete(removableEdgeFilling));
      }
      return state;
    }

    case 'ADD_EDGE_FILLING': {
     const newEdgeFilling = fromJS(action.edgeFilling);
      if (state.get('edgeFillings').find(edgeFilling => edgeFilling.get('id') === newEdgeFilling.get('id')) === undefined) {
        return state.set('edgeFillings', state.get('edgeFillings').push(newEdgeFilling));
      }
      return state;
    }

    case 'CHANGE_EDGE_FILLING': {
      const changingIndex = state.get('edgeFillings').findIndex(edgeFilling => edgeFilling.get('id') === fromJS(action.edgeFilling.id));

      if (changingIndex === -1) return state;

      if (state.get('edgeFillings').get(changingIndex) === fromJS(action.edgeFilling)) {
        return state;
      }
      const newEdgeFillings = state.get('edgeFillings').set(changingIndex, fromJS(action.edgeFilling));
      return state.set('edgeFillings', newEdgeFillings);
    }

    case 'UPDATE_LAST_PATH': {
      const changingIndex = state.get('edgeFillings').findIndex(edgeFilling => edgeFilling.get('id') === action.edgeFillingId);
      let changingMacadam = state.get('edgeFillings').get(changingIndex);
      let paths = changingMacadam.get('paths');
      paths = paths.set(paths.size - 1, fromJS(action.path));
      const newEdgeFillings = state.get('edgeFillings').set(changingIndex, changingMacadam.set('paths', paths));
      return state.set('edgeFillings', newEdgeFillings);
    }

    case 'ADD_PATH_TO_LAST_EDGE_FILLING': {
      const changingEdgeFilling = state.get('edgeFillings').last();
      let paths = changingEdgeFilling.get('paths');
      paths = paths.push(fromJS(action.path));
      const newEdgeFillings = state.get('edgeFillings').set(state.get('edgeFillings').size - 1, changingEdgeFilling.set('paths', paths));
      return state.set('edgeFillings', newEdgeFillings);
    }

    case 'REMOVE_LAST_PATH': {
      const changingIndex = state.get('edgeFillings').findIndex(mass => mass.get('id') === action.macadamId);
      let changingEdgeFilling = state.get('edgeFillings').get(changingIndex);
      let paths = changingEdgeFilling.get('paths');
      paths = paths.pop();
      const newEdgeFillings = state.get('edgeFillings').set(changingIndex, changingEdgeFilling.set('paths', paths));
      return state.set('edgeFillings', newEdgeFillings);
    }

    default: {
      return state;
    }
  }
};
